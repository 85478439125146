export function validateForm(data, product) {
  const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  const phonePattern = /^09[0-9]{8}$/;

  const {
    name = '',
    email = '',
    phone = '',
    certificate_number = '',
    contact_address = {
      city: '',
      district: '',
      zip: '',
      detail: '',
    },
    private_file,
    meal_info,
    id_card_number,
    national_examination_year,
    birthday,
    expertise,
    bank_account_last_5_digits,
    invoice,
  } = data;

  const errFields = {};

  if (!name) {
    errFields.name = '中文名稱為必填';
  }
  if (!email) {
    errFields.email = 'EMAIL為必填';
  } else if (!emailPattern.test(email)) {
    errFields.email = 'EMAIL格式錯誤';
  }
  if (!phone) {
    errFields.phone = '手機為必填';
    // } else if (!phonePattern.test(phone)) {
    //   errFields.phone = '手機格式錯誤';
  }
  if (!certificate_number) {
    errFields.certificate_number = '專利師證書字號為必填';
  }

  if (!contact_address.city) {
    errFields.contact_address_city = '聯絡地址-縣市為必填';
  }

  if (contact_address.city !== '海外' && !contact_address.district) {
    errFields.contact_address_town = '聯絡地址-鄉鎮市區為必填';
  }
  if (contact_address.city !== '海外' && !contact_address.zip) {
    errFields.contact_address_zip_code = '聯絡地址-郵政編碼為必填';
  }

  if (!contact_address.detail) {
    errFields.contact_address_detail = '聯絡地址-詳細內容為必填';
  }

  if (product.private_file_required) {
    if (!private_file) {
      errFields.private_file = '附加檔案-為必填';
    }
  }

  if (product.meal_info_required) {
    if (!meal_info) {
      errFields.meal_info = '用餐資訊-為必填';
    }
  }

  if (product.invoice_required) {
    if (invoice?.type === 'company') {
      if (!invoice?.invoice_title) {
        errFields.invoice_title = '收據抬頭-為必填';
      }
      if (!invoice?.tax_id_number) {
        errFields.tax_id_number = '統一編號-為必填';
      }
    }
  }

  if (product.is_pre_training_course) {
    if (!id_card_number) {
      errFields.id_card_number = '身分證字號-為必填';
    }
    if (!national_examination_year) {
      errFields.national_examination_year = '國考年度-為必填';
    }
    if (!birthday) {
      errFields.birthday = '生日-為必填';
    }
    if (!expertise) {
      errFields.expertise = '專長-為必填';
    }
    if (!bank_account_last_5_digits) {
      errFields.bank_account_last_5_digits = '帳號資訊-為必填';
    }
  }

  return errFields;
}
