export const formSpec = {
  schema: {
    title: '',
    type: 'object',
    required: ['name', 'phone', 'email', 'certificate_number'],
    properties: {
      member_id: {
        title: '會員編號',
        type: 'number',
      },
      name: {
        title: '姓名',
        type: 'string',
      },
      certificate_number: {
        title: '專利師證書字號(或專利代理人證號)',
        type: 'string',
      },
      agency_name: {
        title: '執行業務處所',
        type: 'string',
      },
      phone: {
        title: '電話',
        type: 'string',
      },
      email: {
        title: 'EMAIL',
        type: 'string',
      },
      contact_address: {
        type: 'object',
        title: '聯絡地址',
        properties: {
          city: {
            type: 'string',
          },
          district: {
            type: 'string',
          },
          zip: {
            type: 'string',
          },
          detail: {
            type: 'string',
          },
        },
      },
    },
  },
  uiSchema: {
    contact_address: {
      'ui:field': 'rev-address-field',
      'rev:css': "& > label { &::before {content: ' * '; color: red;} }",
    },
    private_file: {
      'ui:widget': 'private-file-upload-widget',
    },
    note: {
      'ui:widget': 'textarea',
    },
    trademark_info: {
      'ui:help': '若無則可省略不填',
    },
    birthday: {
      'ui:help': '格式範例: 1990-01-02',
    },
  },
};

export const InvoiceField = {
  type: 'object',
  title: '發票/收據開立',
  properties: {
    type: {
      type: 'string',
      title: '類型',
      enum: ['personal', 'company'],
      enumNames: ['個人（二聯式）', '公司報帳（三聯式）'],
      default: 'personal',
    },
  },
  dependencies: {
    type: {
      oneOf: [
        {
          properties: {
            type: {
              enum: ['personal'],
            },
          },
        },
        {
          properties: {
            type: {
              enum: ['company'],
            },
            invoice_title: {
              type: 'string',
              title: '發票/收據抬頭',
            },
            tax_id_number: {
              type: 'string',
              title: '統一編號',
            },
          },
        },
      ],
    },
  },
};

export const PreTrainingField = {
  properties: {
    id_card_number: {
      title: '身分證字號',
      type: 'string',
    },
    national_examination_year: {
      title: '通過專利師國考年度(西元)',
      type: 'string',
    },
    birthday: {
      title: '出生年月日',
      type: 'string',
    },
    expertise: {
      title: '專業領域(個人專長，非國考類別)',
      type: 'string',
    },
    bank_account_last_5_digits: {
      title: '匯款帳號後5碼',
      type: 'string',
    },
  },
};
