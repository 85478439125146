import React from 'react';
import styled from 'styled-components';
import qs from 'query-string';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import withPageEntry from '../../withPageEntry';
import * as AppActions from '../../AppActions';
import * as Ant from 'antd';
import Theme from '../../Theme';
import TwpaaEventRegistrationModal from '../../Components/TwpaaEventRegistrationModal';
import {getDateLabel, getDisableLabels} from '../../Components/TwpaaCourseItem';
import {useOutlet} from 'reconnect.js';
import {Download} from '@styled-icons/evaicons-solid/Download';
import {getCdnUrl} from '../../Utils/CdnUtil';
import {renderCategoryOption} from '../../Components/TwpaaCourseItem';

function ProductDetail(props) {
  const {
    pageContext: {prebuiltProduct = null},
  } = props;
  const {id} = qs.parse(props.location.search);
  const [user] = useOutlet('user');
  const [product, setProduct] = React.useState(prebuiltProduct);
  const [product2, setProduct2] = React.useState(null);
  const [regModalData, setRegModalData] = React.useState({
    visible: false,
  });
  const [allRegs, setAllRegs] = React.useState([]);

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      try {
        setProduct(
          (
            await AppActions.twpaaFetchEventList({
              query: {id},
              paging: {
                offset: 0,
                limit: 1,
              },
            })
          ).results[0],
        );
        setAllRegs(
          await JStorage.fetchAllDocuments(
            'event_registration',
            {
              event_id: id,
              status: {
                $ne: 'failure',
              },
            },
            ['serial_number'],
            {
              product: 0,
            },
          ),
        );
      } catch (ex) {
        console.warn('ProductDetail.useEffect ex', ex);
      }
      AppActions.setLoading(false);
    }

    if (!prebuiltProduct && id) {
      fetchData();
    }
  }, [id, prebuiltProduct]);

  React.useEffect(() => {
    async function fetchRelatedProduct() {
      try {
        const query = product.parent
          ? {
              id: product.parent,
            }
          : {
              parent: product.id,
            };

        setProduct2(
          (
            await AppActions.twpaaFetchEventList({
              query,
              paging: {
                offset: 0,
                limit: 1,
              },
            })
          ).results[0],
        );
      } catch (ex) {
        console.warn('ProductDetail.useEffect ex', ex);
      }
    }

    if (product) {
      fetchRelatedProduct();
    }
  }, [product]);

  if (!prebuiltProduct && !product) {
    return <div style={{minHeight: 'calc(100vh)'}} />;
  }

  const courseImage =
    (product.images && product.images[0]?.expected_url) ||
    '/images/default_twpaa_course.png';

  const disableLabels = getDisableLabels(product);

  return (
    <Wrapper>
      <main>
        <section className="breadcrumb">
          <ul>
            <li>
              <a
                href={'/'}
                onClick={(e) => {
                  e.preventDefault();
                  AppActions.navigate('/');
                }}>
                首頁
              </a>
            </li>
            <li>
              <a
                href={'/products/'}
                onClick={(e) => {
                  e.preventDefault();
                  AppActions.navigate('/products/');
                }}>
                報名專區
              </a>
            </li>
          </ul>
        </section>

        <section className="top">
          <img src={courseImage} alt="course" />

          <div className="content">
            <h2>{product.name}</h2>
            <div className="date">{getDateLabel(product)}</div>
            <div className="info">
              <div>
                時間: {product.open_time} ~ {product.close_time}
              </div>
              <div>地點: {product.location}</div>
              <div>主辦單位: {product.host}</div>
              <div>主講人: {product.lecturer}</div>
              <div>時數: {product.hours}</div>
              <div style={{marginTop: 5}}>
                <ul className="hours-cat">
                  {product.hours_catagory?.option_1 &&
                    renderCategoryOption('option_1')}
                  {product.hours_catagory?.option_2 &&
                    renderCategoryOption('option_2')}
                  {product.hours_catagory?.option_3 &&
                    renderCategoryOption('option_3')}
                  {product.hours_catagory?.option_4 &&
                    renderCategoryOption('option_4')}
                </ul>
              </div>

              <div className="price">
                <label>報名費</label>
                {product.old_event_id ? (
                  <div>
                    <div>會員：{product.price}</div>
                    <div>非會員：{product.price_non_member}</div>
                  </div>
                ) : (
                  <div>
                    {product.join_member && <div>會員：{product.price}</div>}
                    {product.join_non_member && (
                      <div>非會員：{product.price_non_member}</div>
                    )}
                  </div>
                )}
              </div>
            </div>
            {disableLabels.length === 0 ? (
              <Ant.Button
                style={{borderRadius: 100, minWidth: 200}}
                disabled={
                  user ? !product.join_member : !product.join_non_member
                }
                type="primary"
                size="large"
                onClick={() => setRegModalData({visible: true, product})}>
                報名{product.online ? '線上' : '實體'}課程
              </Ant.Button>
            ) : (
              <div style={{color: '#666', fontSize: 20}}>
                {disableLabels[0]}
              </div>
            )}
            {product2 && (
              <Ant.Button
                type="link"
                style={{borderRadius: 100, minWidth: 200, paddingLeft: 0}}
                size="large"
                onClick={() => {
                  AppActions.navigate(`/product/?id=${product2.id}`);
                }}>
                → {product2.online ? '線上' : '實體'}課程
              </Ant.Button>
            )}
          </div>
        </section>

        <section className="course-intro">
          <h2>活動介紹</h2>
          {product?.richtext?.html && (
            <div
              dangerouslySetInnerHTML={{
                __html: product.richtext.html,
              }}
            />
          )}
          {product?.detail_file && (
            <Ant.Button
              type="link"
              target={'_blank'}
              style={{padding: 0, marginTop: 15}}
              href={getCdnUrl(product.detail_file)}>
              <Download size={24} />
              下載介紹檔案
            </Ant.Button>
          )}
        </section>

        {allRegs?.length > 0 && (
          <section className="course-intro">
            <h2>報到序號表</h2>
            <Ant.Table
              columns={[
                {
                  title: '報名序號',
                  key: 'serial_number',
                  render: (_, record) => {
                    return !record.serial_number
                      ? '---'
                      : `${product.registration_number_list || ''}${(
                          '0000' + record.serial_number || ''
                        ).slice(-4)}`;
                  },
                },
                {
                  title: '姓名',
                  key: 'name',
                  render: (_, record) => {
                    const name = record.user_profile?.name || record.name || '';
                    if (name.length <= 2) {
                      const [first, ...others] = name;
                      return (
                        first +
                        new Array({length: others.length})
                          .map((it) => '〇')
                          .join('')
                      );
                    } else {
                      const first = name.slice(0, 1);
                      const mid = name.slice(1, -1);
                      const last = name.slice(-1);
                      return (
                        first +
                        new Array({length: mid.length})
                          .map((it) => '〇')
                          .join('') +
                        last
                      );
                    }
                  },
                },
                {
                  title: '執行業務處所',
                  key: 'agency_name',
                  dataIndex: 'agency_name',
                },
              ]}
              dataSource={allRegs.filter((it) => it.status === 'success')}
              rowKey={'id'}
            />
          </section>
        )}
      </main>

      <TwpaaEventRegistrationModal
        close={() => setRegModalData({visible: false})}
        {...regModalData}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: var(--topNavBarHeight);
  min-height: var(--contentMinHeight);

  h2 {
    color: ${Theme.colors.main};
  }

  & > main {
    max-width: 1080px;
    margin: 0 auto;
    padding: 40px 20px;
  }

  & section.breadcrumb {
    & ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
      gap: 6px;
      & > * {
        &:not(:last-child)::after {
          content: '/';
          padding-left: 6px;
        }
      }
    }
  }

  & section.top {
    margin-top: 20px;
    display: flex;
    gap: 20px;

    & > img {
      flex: 0 0 300px;
      height: 300px;
      overflow: hidden;
      background-color: #f0f4f8;
      object-fit: cover;
      border-radius: 12px;
    }

    & > .content {
      flex: 1;

      & > .date {
        margin: 15px 0;
      }

      & > .info {
        margin: 15px 0;
        padding: 15px 0;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        & > .price {
          margin-top: 30px;
          display: flex;
          gap: 20px;
          & label {
            font-weight: bold;
            color: ${Theme.colors.main};
          }
        }
      }
    }

    ul.hours-cat {
      display: flex;
      gap: 6px;
      list-style-type: none;
      padding: 0;
      margin: 0;

      & > li {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 26px;
        height: 26px;
        border-radius: 13px;
      }
    }

    @media only screen and (max-width: 640px) {
      flex-direction: column;
      & > img {
        align-self: center;
      }
    }
  }

  section.course-intro {
    margin: 40px 0;
    padding: 40px 0;
    border-top: 1px solid #ccc;

    & > h2 {
      margin-bottom: 10px;
    }
  }
`;

export default withPageEntry(ProductDetail);
