import React from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import {
  formSpec as _formSpec,
  InvoiceField,
  PreTrainingField,
} from './form-spec';
import GenericForm from 'rev.sdk.js/Generic/Form';
import {useOutlet} from 'reconnect.js';
import * as AppActions from '../../AppActions';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import RjsfAddressField from '../RjsfAddressField';
import RjsfPrivateFileUploadWidget from '../RjsfPrivateFileUploadWidget';
import {validateForm} from './util';
import cloneDeep from 'lodash/cloneDeep';

export default function TwpaaEventRegistrationModal(props) {
  const {visible, close} = props;
  return (
    <Ant.Modal
      title={null}
      footer={null}
      bodyStyle={{padding: 0}}
      width={600}
      open={visible}
      onOk={close}
      onCancel={close}
      destroyOnClose={true}>
      {visible && <ModalContent {...props} />}
    </Ant.Modal>
  );
}

function ModalContent(props) {
  const {product} = props;
  const [user] = useOutlet('user');
  const [formSpec, setFormSpec] = React.useState(null);
  const [defaultValues, setDefaultValues] = React.useState({});
  const [allRegs, setAllRegs] = React.useState(null);
  const [allRelatedRegs, setAllRelatedRegs] = React.useState(null);
  const [validationErrorList, setValidationErrorList] = React.useState([]);

  React.useEffect(() => {
    async function fetchData() {
      let [error, resp] = await AppActions.tryError(
        JStorage.fetchAllDocuments(
          'event_registration',
          {
            event_id: product.id,
            status: {$ne: 'failure'},
          },
          undefined,
          {product: 0},
        ),
      );
      if (error) {
        console.warn('REV_DBG', error);
      } else {
        setAllRegs(resp);
      }

      if (!user) {
        return;
      }

      const relatedQuery = {
        status: {$ne: 'failure'},
        owner: user.sub,
      };

      if (product.parent) {
        relatedQuery.event_id = product.parent;
      } else {
        let result = await AppActions.tryError(
          JStorage.fetchOneDocument('product', {
            parent: product.id,
          }),
        );
        const relatedCourse = result[1];
        if (relatedCourse) {
          relatedQuery.event_id = relatedCourse.id;
        }
      }

      if (relatedQuery.event_id) {
        [error, resp] = await AppActions.tryError(
          JStorage.fetchAllDocuments(
            'event_registration',
            relatedQuery,
            undefined,
            {product: 0},
          ),
        );
        if (error) {
          console.warn('REV_DBG', error);
        } else {
          setAllRelatedRegs(resp);
        }
      }
    }

    if (product) {
      fetchData();
    }
  }, [product, user]);

  React.useEffect(() => {
    let nextFormSpec = cloneDeep(_formSpec);
    if (user) {
      const dv = {};
      for (const k in _formSpec.schema.properties) {
        if (user?.data[k]) {
          dv[k] = user?.data[k];
        }
      }
      setDefaultValues(dv);

      for (const k of ['member_id', 'name', 'certificate_number']) {
        nextFormSpec.schema.properties[k].readOnly = true;
      }
    } else {
      delete nextFormSpec.schema.properties.member_id;
    }

    if (product.private_file_required) {
      nextFormSpec.schema.required.push('private_file');
      nextFormSpec.schema.properties.private_file = {
        type: 'string',
        title: '附加檔案',
      };
    }

    if (product.invoice_required) {
      const nextInvoiceField = cloneDeep(InvoiceField);
      nextFormSpec.schema.required.push('invoice');
      if (user) {
        nextInvoiceField.dependencies.type.oneOf[1].properties.invoice_title.default =
          user?.data?.receipt_name || '';
        nextInvoiceField.dependencies.type.oneOf[1].properties.tax_id_number.default =
          user?.data?.business_id_number || '';
      }
      nextFormSpec.schema.properties.invoice = nextInvoiceField;
    }

    if (product.meal_info_required) {
      nextFormSpec.schema.properties.meal_info = {
        title: '用餐資訊',
        type: 'string',
        enum: ['葷', '素(全素)', '素(蛋奶素)', '不需用餐'],
      };
      nextFormSpec.schema.required.push('meal_info');
    }

    if (product.trademark_info_required) {
      nextFormSpec.schema.properties.trademark_info = {
        title: '商標代理人字號',
        type: 'string',
      };
    }

    if (product.is_pre_training_course) {
      for (const k in PreTrainingField.properties) {
        nextFormSpec.schema.properties[k] = {...PreTrainingField.properties[k]};
        nextFormSpec.schema.required.push(k);
      }
    }

    nextFormSpec.schema.properties.note = {
      title: '備註',
      type: 'string',
    };

    setFormSpec(nextFormSpec);
  }, [user, product]);

  if (allRegs === null) {
    return (
      <Wrapper>
        <h2>報名{product.name}</h2>
      </Wrapper>
    );
  } else if (product.max_reg_cnt && allRegs.length >= product.max_reg_cnt) {
    return (
      <Wrapper>
        <h2>報名{product.name}</h2>
        <h3>抱歉! 已額滿</h3>
      </Wrapper>
    );
  } else if (
    user &&
    allRegs.find((it) => it.owner === user.sub && it.status !== 'failure')
  ) {
    return (
      <Wrapper>
        <h2>報名{product.name}</h2>
        <h3>您已有報名紀錄</h3>
      </Wrapper>
    );
  } else if (user && allRelatedRegs?.length > 0) {
    return (
      <Wrapper>
        <h2>報名{product.name}</h2>
        <h3>您已有對應課程報名紀錄</h3>
      </Wrapper>
    );
  } else if (!formSpec) {
    return (
      <Wrapper>
        <h3>Loading...</h3>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <h2 style={{marginBottom: 12}}>報名{product.name}</h2>
      <h3 style={{marginBottom: 20, color: 'red'}}>
        已申請入會的會員請注意：請先登入會員帳號後，再送出報名表單，否則系統將會以非會員身份處理！
      </h3>
      {formSpec && (
        <GenericForm
          {...formSpec}
          instance={defaultValues}
          onSubmit={() => {
            AppActions.setLoading(false);
          }}
          rjsfProps={{
            fields: {
              'rev-address-field': RjsfAddressField,
            },
            widgets: {
              'private-file-upload-widget': RjsfPrivateFileUploadWidget,
            },
          }}
          renderCustomSubmitButton={({values: formData}) => {
            return (
              <Ant.Button
                type="primary"
                style={{marginTop: 20}}
                onClick={async () => {
                  const validationErrorMap = validateForm(
                    {...formData},
                    product,
                  );
                  const validationErrorList_ = Object.keys(
                    validationErrorMap,
                  ).map((k) => validationErrorMap[k]);
                  if (validationErrorList_.length > 0) {
                    setValidationErrorList(validationErrorList_);
                    return;
                  }

                  try {
                    AppActions.setLoading(true);
                    await AppActions.twpaaCreateEventRregistration({
                      event_id: product.id,
                      data: formData,
                    });
                    Ant.message.success('已成功送出報名');
                    props.close();
                  } catch (ex) {
                    console.warn('REV_DBG', ex);
                    if (ex?.response?.error === 'max_reg_cnt_reached') {
                      Ant.message.warn('無法送出! 報名已額滿');
                    } else {
                      Ant.message.warn('API Error');
                    }
                  } finally {
                    AppActions.setLoading(false);
                  }
                }}>
                確認送出
              </Ant.Button>
            );
          }}
        />
      )}
      {validationErrorList.length > 0 && (
        <div className="err-fields">
          <div> ----- 請修正以下欄位後方可送出 ----- </div>
          <ul>
            {validationErrorList.map((it, idx) => {
              return <li key={idx}>{it}</li>;
            })}
          </ul>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
`;
